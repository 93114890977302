import React from "react"
import { Parallax } from 'react-parallax'

import MainNavBar from "../mainNavBar"
import CaptionSource from "./captionSource"

const HeaderSource = ({ siteTitleContact }) => (
  <Parallax
      bgImage={require('../../images/hero-source-p.jpg')}
      bgImageAlt="LifeSync Source"
      strength="150"
      className="sourceParallax"
  >
    <header className="headerSource">
      <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
      <MainNavBar />
      <CaptionSource />
    </header>
  </Parallax>
)

export default HeaderSource
