import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CaptionSource = () => (
  <Container>
        <Row>
            <Col>
                <div className="captionSource">
                    <h1>Your end-to-end solution for custom medical cable manufacturing</h1>
                    <p>Custom design, manufacturing, and fulfillment of medical cables and connections. </p>
                </div>
            </Col>
        </Row>
  </Container>
)

export default CaptionSource
