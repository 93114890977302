import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const SourceTwoColCopyRightTwo = () => (
  <div className="sourceTwoColCopyRightTwo">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 2}} className="twoColCopyRightCopy">
                <Row>
                    <Col>
                        <div className="copyRightWrap">
                            <h2>Quality assurance</h2>
                            <p>LifeSync Source manufactures our products in certified facilities that meet EN ISO 13485 and ISO 9001 requirements and follow FDA cGMP guidelines. Our products conform to the industry standards of ANSI/AAMI EC-53, ANSI/AAMI HF-18, IEC 60601, UL, and DIN 42-802. <br />
                            <br/>
                            Our Quality Assurance team rigorously tests for mechanical, electrical, sterilization, environmental, and chemical analysis attributes. Your custom cable and monitoring products will be verified against ISO 10993-5 and ISO 10993-10 standards.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/ls-quality-assurance-p.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default SourceTwoColCopyRightTwo
