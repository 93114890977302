import React from "react"
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import IntroImgCardiac from '../../images/source-proven-process.png'

const SourceProvenProcess = () => (
  <div className="provenProcess" style={{paddingTop:'0'}}>
    <Container className="sixsteps" style={{paddingTop:'0'}}>
      <Row>
        <Col className="introduction">
          <h3 style={{fontWeight: 'normal'}}>Let's create together</h3>
          <p>Our collaborative process involves six phases of personalized interactions because we think more connection around your idea is better than less.</p>
          <p>LifeSync partners rely on our medical R&D capacities, our manufacturing prowess, and design capabilities to meet your custom interconnect needs. Our collaborative process sets the stage for targeted production, and our global manufacturing capacity allows for production efficiencies, while you own the design on completion.</p>
          <p>Our best solutions come from sharing expertise and experience, whether across town or across the globe.</p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={7}>
            <div className="imgWrap">
              <img src={IntroImgCardiac} alt="Cardiac Proven Process" className="resp-img" />
            </div>
            <p className="provendescription">LifeSync Source uses a synchronous process in all collaborations.</p>
        </Col>
        <Col md={5}>
          <ol>
            <li>
              <h4>Requirements</h4>
              <ul className="descriptionSublist">
                <li>Needs analysis</li>
                <li>Solution expectations</li>
                <li>Requirements gathering</li>
                <li>Stakeholder alignment</li>
              </ul>
            </li>
            <li>
              <h4>Technical / Design</h4>
              <ul className="descriptionSublist">
                <li>Verify technical specifications</li>
                <li>Establish functional specifications</li>
                <li>Prototypes</li>
                <li>Accelerate adoption</li>
              </ul>
            </li>
            <li>
              <h4>Design verification</h4>
              <ul className="descriptionSublist">
                <li>Protocol</li>
                <li>In-house testing</li>
                <li>Third-party verification</li>
                <li>Customer approval</li>
              </ul>
            </li>
            <li>
              <h4>Process qualification</h4>
              <ul className="descriptionSublist">
                <li>Process flow</li>
                <li>Process Failure Mode Effects Analysis (PFMEA)</li>
                <li>Control plan</li>
                <li>Installation Qualification (IQ)</li>
                <li>Operational Qualification (OQ)</li>
                <li>Performance Qualification (PQ)</li>
                <li>100% functional test</li>
              </ul>
            </li>
            <li>
              <h4>Production</h4>
              <ul className="descriptionSublist">
                <li>First article inspection (FAI)</li>
                <li>Schedule production</li>
                <li>Vendor-Managed Inventory (VMI)</li>
                <li>Simplified supply chain</li>
              </ul>
            </li>
            <li>
              <h4>Customer devotion</h4>
              <ul className="descriptionSublist">
                <li>Personalized account management</li>
                <li>Customized solutions</li>
                <li>Satisfaction assurance</li>
              </ul>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  </div>
)

export default SourceProvenProcess
