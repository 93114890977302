import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const SourceTwoColCopyRight = () => (
  <div className="sourceTwoColCopyRight">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 2}} className="twoColCopyRightCopy">
                <Row>
                    <Col>
                        <div className="copyRightWrap">
                            <h2>
                                Design and<br />
                                engineering support
                            </h2>
                            <p>Our dedicated team of product development specialists works directly with your product management and engineering teams from concept to reality. With teams located at major medical hubs across the country, you have easy access to LifeSync Source during the planning and product development process.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/ls-design-building-p.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default SourceTwoColCopyRight
