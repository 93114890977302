import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

// import Factory from '../images/ls-design-eng-p.jpg'

const SourceTwoColCopyLeftTwo = () => (
  <div className="sourceTwoColCopyLeftTwo">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftCopy">
                <Row>
                    <Col>
                        <div className="copyLeftWrap">
                        <h2>
                                Production and assembly
                            </h2>
                            <p>LifeSync Source owns and operates its own manufacturing facilities to ensure the highest control of product design, production,
                            and quality assurance. LifeSync acts as your global purchasing agent and manufacturer for every component of your custom cable projects.
                            In-house test laboratories ensure a 100% inspection and quality assurance process for your products.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 2}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/ls-design-eng-p.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default SourceTwoColCopyLeftTwo
