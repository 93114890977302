import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const SourceTwoColCopyLeft = () => (
  <div className="sourceTwoColCopyLeft">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftCopy">
                <Row>
                    <Col>
                        <div className="copyLeftWrap">
                            <h2>
                                Benefits of partnering<br />
                                with LifeSync
                            </h2>
                            <p>LifeSync Source (formerly American BioSurgical) is the leading custom medical cable and interconnect manufacturer that can meet your technology to patient connection requirements. For over ten years we've helped our partners all the way from concept and design, through assembly to supply chain fulfillment.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 2}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/ls-benefits-of-partnering-p.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default SourceTwoColCopyLeft
